// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-profile-js": () => import("/opt/build/repo/web/src/templates/profile.js" /* webpackChunkName: "component---src-templates-profile-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/web/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/web/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-js": () => import("/opt/build/repo/web/src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-book-js": () => import("/opt/build/repo/web/src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-categories-js": () => import("/opt/build/repo/web/src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-corporate-gurus-js": () => import("/opt/build/repo/web/src/pages/corporate-gurus.js" /* webpackChunkName: "component---src-pages-corporate-gurus-js" */),
  "component---src-pages-entrepreneurs-js": () => import("/opt/build/repo/web/src/pages/entrepreneurs.js" /* webpackChunkName: "component---src-pages-entrepreneurs-js" */),
  "component---src-pages-humanitarians-js": () => import("/opt/build/repo/web/src/pages/humanitarians.js" /* webpackChunkName: "component---src-pages-humanitarians-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-js": () => import("/opt/build/repo/web/src/pages/landing-page.js" /* webpackChunkName: "component---src-pages-landing-page-js" */),
  "component---src-pages-legal-practitioners-js": () => import("/opt/build/repo/web/src/pages/legal-practitioners.js" /* webpackChunkName: "component---src-pages-legal-practitioners-js" */),
  "component---src-pages-media-personalities-js": () => import("/opt/build/repo/web/src/pages/media-personalities.js" /* webpackChunkName: "component---src-pages-media-personalities-js" */),
  "component---src-pages-most-influential-js": () => import("/opt/build/repo/web/src/pages/most-influential.js" /* webpackChunkName: "component---src-pages-most-influential-js" */),
  "component---src-pages-movie-stars-js": () => import("/opt/build/repo/web/src/pages/movie-stars.js" /* webpackChunkName: "component---src-pages-movie-stars-js" */),
  "component---src-pages-music-industry-js": () => import("/opt/build/repo/web/src/pages/music-industry.js" /* webpackChunkName: "component---src-pages-music-industry-js" */),
  "component---src-pages-politicians-js": () => import("/opt/build/repo/web/src/pages/politicians.js" /* webpackChunkName: "component---src-pages-politicians-js" */),
  "component---src-pages-power-ladies-js": () => import("/opt/build/repo/web/src/pages/power-ladies.js" /* webpackChunkName: "component---src-pages-power-ladies-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/build/repo/web/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-spiritual-leaders-js": () => import("/opt/build/repo/web/src/pages/spiritual-leaders.js" /* webpackChunkName: "component---src-pages-spiritual-leaders-js" */),
  "component---src-pages-sporting-personalities-js": () => import("/opt/build/repo/web/src/pages/sporting-personalities.js" /* webpackChunkName: "component---src-pages-sporting-personalities-js" */),
  "component---src-pages-stage-artists-js": () => import("/opt/build/repo/web/src/pages/stage-artists.js" /* webpackChunkName: "component---src-pages-stage-artists-js" */),
  "component---src-pages-statesmen-js": () => import("/opt/build/repo/web/src/pages/statesmen.js" /* webpackChunkName: "component---src-pages-statesmen-js" */),
  "component---src-pages-traditional-leaders-js": () => import("/opt/build/repo/web/src/pages/traditional-leaders.js" /* webpackChunkName: "component---src-pages-traditional-leaders-js" */)
}

